import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RecurringTypeRow from '@root/pages/RecurringTypes/editable-recurring-type';
import {
  addRecurringTypes,
  editRecurringTypes,
  getRecurringTypes,
  IRecurringType,
} from '@root/services/api/requests/recurringTypes';
import { setRecurringTypes } from '@root/store/entities/actions';
import { selectRecurringTypes } from '@root/store/entities/selectors';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import { emptyItem, headCells } from './static-data';

const RecurringTypes: FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(selectUserPermissions);
  const list = useSelector(selectRecurringTypes);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [newItem, setNewItem] = React.useState<IRecurringType | null>(null);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readKycVerificationListLevel1)) {
      fetchList();
    }
  }, []);

  const fetchList = async () => {
    try {
      const response = await getRecurringTypes();
      dispatch(setRecurringTypes(response.data));
    } catch (e) {
      console.error('fetch KYC List error', e);
    } finally {
      setLoading(false);
    }
  };

  const addUpdateRow = async (data: IRecurringType) => {
    try {
      const params = {
        ...data,
        lengthInDays: Number(data.lengthInDays),
      };
      if (data.id) {
        await editRecurringTypes(params);
      } else {
        await addRecurringTypes(params);
      }
      setNotification('success', {
        message: 'Success',
      });
      setNewItem(null);
      fetchList();
      return true;
    } catch (e) {
      console.error('UPDATE KYC ITEM ERROR', e);
      return false;
    }
  };

  const addNewItem = () => {
    setNewItem(emptyItem);
  };

  const deleteNewItem = () => {
    setNewItem(null);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Recurring Types</Title>
            <Can perform={Permissions.createRecurringType}>
              <Box display={'flex'}>
                <Button
                  onClick={addNewItem}
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<AddCircleIcon />}
                >
                  Add Recurring Type
                </Button>
              </Box>
            </Can>
          </div>
        </Paper>
      </Grid>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead headCells={headCells} order={'asc'} orderBy={'id'} classes={classes} />
            <TableBody>
              {loading && <TableSkeleton />}
              {!loading && newItem && (
                <RecurringTypeRow
                  key="new-item"
                  onEditRow={addUpdateRow}
                  deleteNewItem={deleteNewItem}
                  item={newItem}
                />
              )}
              {!loading &&
                list?.map((item) => (
                  <RecurringTypeRow
                    key={item.id}
                    onEditRow={addUpdateRow}
                    deleteNewItem={deleteNewItem}
                    item={item}
                  />
                ))}
              {!loading && !list?.length && (
                <TableRow>
                  <TableCell>There are no Recurring Types</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default RecurringTypes;
