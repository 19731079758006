import { ProductContext } from '@root/components/CreateEditProduct/product-context';
import { FiltersKeys, IFilters } from '@root/components/CreateEditProduct/types';
import changePlanStatus, { IParams } from '@root/services/api/requests/changePlanStatus';
import deletePlan from '@root/services/api/requests/deletePlan';
import getPlanById from '@root/services/api/requests/getPlanById';
import { getPlanMissedCountries } from '@root/services/api/requests/getPlanMissedCountries';
import { IPlan, IProductWithPlans } from '@root/services/api/requests/getProductPlans';
import { editPlan } from '@root/store/entities/actions';
import { selectProductForEditing, selectRecurringTypes } from '@root/store/entities/selectors';
import { IOption } from '@root/utils/commonTypes';
import { setSuccessNotification } from '@root/utils/notifications';
import pageLinks from '@root/utils/pageLinks';
import { push } from 'connected-react-router';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import editProductPlan from '../../services/api/requests/editProductPlan';

import { useDispatch, useSelector } from 'react-redux';

export const usePlanController = () => {
  const productForEditing = useSelector(selectProductForEditing);
  const dispatch = useDispatch();
  const recurringTypes = useSelector(selectRecurringTypes);
  const [filters, setFilters] = useState<IFilters>({
    status: 'All',
    recurringType: 'All',
    isRegional: false,
  });
  const [groupList, setGroupList] = useState<IPlan[][][]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  // const [newStatusForMultiplePlans, setNewStatusForMultiplePlans] = useState<string>('');
  const [editingPlanId, setEditingPlanId] = useState<number | null>(null);
  const { product, fetchProduct } = useContext(ProductContext);
  const [newPlanName, setNewPlanName] = useState<string>('');
  const [newPrices, setNewPrices] = useState<{ setupPrice: number; recurringPrice: number }>({
    setupPrice: 0,
    recurringPrice: 0,
  });
  const [planCountries, setPlanCountries] = useState<string[] | null>(null);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [chooseCountries, setChooseCountries] = useState<string[]>([]);
  const [isGrid, setIsGrid] = useState(false);
  const isEdit = product?.id;

  const groups = useMemo(() => {
    return sortBy(
      Object.values(groupBy(product?.plans, 'planGroupId')).map((group) => {
        let main;
        let active: IPlan[] = [];
        let inactive: IPlan[] = [];

        for (let i = 0; i < group.length; i++) {
          const isRegionalPlan = group[i].isRegional;
          if (!isRegionalPlan) {
            main = group[i];
          } else if (isRegionalPlan && group[i].status === 'active') {
            active.push(group[i]);
          } else {
            inactive.push(group[i]);
          }
        }
        return [main as IPlan, ...active, ...inactive];
      }),
      [(plan: IPlan[]) => plan[0]?.name]
    ).reduce(
      (acc, val: IPlan[]) => {
        if (val[0].intervalSize === 365) {
          acc[1] = [...acc[1], val];
        } else {
          acc[0] = [...acc[0], val];
        }

        return acc;
      },
      [[], []] as Array<IPlan[][]>
    );
  }, [product]);

  const isAddon = (product: IProductWithPlans | null) => {
    return product && product.urlParam === 't-addons';
  };

  const getAvailableCountries = useCallback(async (planGroupId: IPlan['planGroupId']) => {
    try {
      const response = await getPlanMissedCountries(planGroupId);
      return response.data.data.map((item) => item.code);
    } catch (error) {
      console.error('Error: ', error);
      return undefined;
    }
  }, []);

  const createPlan = async (planGroupId: number | void) => {
    try {
      if (planGroupId) {
        const plan = product?.plans.find(
          (plan) => plan.planGroupId === planGroupId && !plan.isRegional
        );

        const fullPlanData = await getPlanById({
          productId: isEdit ? Number(product?.id) : 0,
          planId: plan?.id as number,
        });

        const availableCountryCodes = await getAvailableCountries(planGroupId);

        const planForEditing = {
          ...fullPlanData.data.data,
          availableCountryCodes: availableCountryCodes?.length ? availableCountryCodes : undefined,
        };

        dispatch(editPlan(planForEditing));
      }

      dispatch(
        push(
          pageLinks.createEditPlan +
            `?productId=${productForEditing?.id || product?.id}${
              isAddon(product) ? '&isAddon=true' : ''
            }${planGroupId ? '&action=create&planGroupId=' + planGroupId : ''}${
              planGroupId ? '&isRegionalPlan=true' : ''
            }`
        )
      );
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleEditPlan = useCallback(
    async (plan: IPlan) => {
      try {
        const fullPlanData = await getPlanById({
          productId: isEdit ? Number(product?.id) : 0,
          planId: plan.id,
        });

        const availableCountryCodes = await getAvailableCountries(plan.planGroupId);

        const planForEditing = {
          ...fullPlanData.data.data,
          availableCountryCodes: availableCountryCodes?.length ? availableCountryCodes : undefined,
        };

        dispatch(editPlan(planForEditing));
        dispatch(
          push(
            pageLinks.createEditPlan +
              `?productId=${product?.id}&isRegionalPlan=${plan.isRegional}&planId=${plan.id}${
                isAddon(product) ? '&isAddon=true' : ''
              }${plan.planGroupId ? `&planGroupId=${plan.planGroupId}` : ''}`
          )
        );
      } catch (e) {
        console.warn('error', e);
      }
    },
    [dispatch, isEdit, product, getAvailableCountries]
  );

  const handleDeletePlan = useCallback(
    async (id: number) => {
      const response = await deletePlan(id);
      if (response) {
        setSuccessNotification('Plan has been deleted');
        fetchProduct().then(console.log);
      }
    },
    [fetchProduct]
  );

  const handlePlanStatusChange = useCallback(
    async (data: IParams) => {
      const response = await changePlanStatus(data);
      if (response) {
        fetchProduct().then(console.log);
      }
    },
    [fetchProduct]
  );

  const showPlanCountries = useCallback(
    (countries: string[] | null) => {
      setPlanCountries(countries);
    },
    [setPlanCountries]
  );

  const recurringOptions = useMemo(() => {
    if (recurringTypes === null) return [];

    return recurringTypes.reduce<IOption[]>(
      (options, value) => {
        options.push({ value: value.code, label: value.code });
        return options;
      },
      [{ label: 'All', value: 'All' }]
    ) as IOption[];
  }, [recurringTypes]);

  const handleFilterChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target;

      if (name === 'isRegional') {
        setFilters((prev: IFilters) => ({ ...prev, [name as string]: !prev.isRegional }));
      } else if (typeof value === 'string') {
        setFilters((prev: IFilters) => ({ ...prev, [name as string]: value }));
      }
    },
    []
  );

  const filterBy = useCallback(
    (filteredGroup: IPlan[][][], filter: FiltersKeys) => {
      return filteredGroup.map((group) =>
        group.map((subGroup) => subGroup.filter((plan) => plan[filter] === filters[filter]))
      );
    },
    [filters]
  );

  const searchByCountries = useCallback((groups: IPlan[][][], countries: string[]) => {
    return groups
      .map((group) =>
        group.map((subGroup) =>
          subGroup.filter((plan) =>
            countries.some((country) => plan.countryCodes.includes(country))
          )
        )
      )
      .map((group) => group.filter((subGroup) => subGroup.length > 0))
      .filter((group) => group.length > 0);
  }, []);

  const handleFilterApply = useCallback(() => {
    let filteredGroups = [...groups];

    if (filters.status !== 'All') {
      filteredGroups = filterBy(filteredGroups, 'status');
    }
    if (filters.recurringType !== 'All') {
      filteredGroups = filterBy(filteredGroups, 'recurringType');
    }
    if (filters.isRegional) {
      filteredGroups = filterBy(filteredGroups, 'isRegional');
    }
    if (chooseCountries.length > 0) {
      filteredGroups = searchByCountries(filteredGroups, chooseCountries);
    }

    setGroupList(
      filteredGroups
        .map((group) => group.filter((subGroup) => subGroup.length > 0))
        .filter((group) => group.length > 0)
    );
  }, [filters, chooseCountries, groups]);

  const handleMultipleSelectItems = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
      if (event.target.checked) {
        setSelectedItems((prev) => [...prev, id]);
      } else {
        setSelectedItems((prev) => prev.filter((item) => item !== id));
      }
    },
    []
  );

  const toggleFilterPanel = useCallback(() => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  }, [isFilterPanelOpen]);

  const handleClearSelectedItems = useCallback(() => {
    setSelectedItems([]);
  }, []);


  // TODO: Multiply functionality
  
  
  // const handleNewMultipleStatusChange = useCallback(
  //   (e: ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
  //     const { value } = e.target;

  //     if (typeof value === 'string') {
  //       setNewStatusForMultiplePlans(value);
  //     }
  //   },
  //   []
  // );

  // const handleUpdateStatusMultiplePlans = useCallback(async () => {
  //   try {
  //     const updatePromises = selectedItems.map((id) =>
  //       changePlanStatus({ id, status: newStatusForMultiplePlans })
  //     );
  //     const responses = await Promise.all(updatePromises);

  //     if (responses.every((response) => response)) {
  //       setSuccessNotification();
  //       fetchProduct().then(console.log);
  //     }

  //     setNewStatusForMultiplePlans('');
  //     setSelectedItems([]);
  //   } catch (e) {
  //     console.warn('Error updating plan statuses', e);
  //   }
  // }, [selectedItems, newStatusForMultiplePlans, fetchProduct]);

  // const handleDeleteMultiplePlans = useCallback(async () => {
  //   try {
  //     const deletePromises = selectedItems.map((id) => deletePlan(id));
  //     const responses = await Promise.all(deletePromises);

  //     if (responses.every((response) => response)) {
  //       setSuccessNotification('All selected plans have been deleted');
  //       fetchProduct().then(console.log);
  //     }

  //     setNewStatusForMultiplePlans('');
  //     setSelectedItems([]);
  //   } catch (e) {
  //     console.warn('Error deleting plans', e);
  //   }
  // }, [selectedItems, fetchProduct]);

  //

  const changeEditingPlanData = useCallback((id: number | null) => {
    setEditingPlanId(id);
  }, []);

  const handleChangeField = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      switch (name) {
        case 'newPlanName':
          setNewPlanName(value);
          break;
        case 'setupPrice':
          setNewPrices((prev) => ({ ...prev, setupPrice: Number(value) }));
          break;
        case 'recurringPrice':
          setNewPrices((prev) => ({ ...prev, recurringPrice: Number(value) }));
          break;
      }
    },
    [setNewPlanName, setNewPrices]
  );

  const submitNewData = useCallback(
    async (id: number) => {
      let plan = null;

      try {
        if (product === null) return;

        const fullPlanData = await getPlanById({
          productId: Number(product.id),
          planId: id,
        });
        plan = fullPlanData.data.data;

        if (plan === null) return;

        plan.name = newPlanName;
        plan.setupPrice = newPrices.setupPrice as number;
        plan.recurringPrice = newPrices.recurringPrice as number;

        if (plan.trialPeriod === null) {
          delete plan.trialPeriod;
        }

        await editProductPlan(plan, product.id.toString());
        await fetchProduct();
      } catch (e) {
        console.warn('error', e);
      }

      setNewPlanName('');
      setNewPrices({ setupPrice: 0, recurringPrice: 0 });
      setEditingPlanId(null);
    },
    [product?.plans, newPlanName, newPrices, setEditingPlanId]
  );

  useEffect(() => {
    if (groups.length === 0) return;

    setGroupList(groups);
  }, [groups]);

  useEffect(() => {
    if (editingPlanId === null) return;

    const plan = product?.plans.find((plan) => plan.id === editingPlanId);
    if (!plan) return;

    setNewPlanName(plan?.name || '');
    setNewPrices({
      setupPrice: plan?.setupPrice || 0,
      recurringPrice: plan?.recurringPrice || 0,
    });
  }, [product, editingPlanId]);

  const state = {
    product,
    isEdit,
    planCountries,
    groupList,
    isGrid,
    groupItemState: {
      newPlanName,
      newPrices,
      editingPlanId,
      selectedItems,
      // newStatusForMultiplePlans,
    },
    filterState: { recurringOptions, filters, isFilterPanelOpen, chooseCountries },
  };

  const handlers = {
    createPlan,
    handleEditPlan,
    handleDeletePlan,
    handlePlanStatusChange,
    showPlanCountries,
    setIsGrid,
    groupItemHandlers: {
      changeEditingPlanData,
      handleMultipleSelectItems,
      handleClearSelectedItems,
      // handleNewMultipleStatusChange,
      // handleUpdateStatusMultiplePlans,
      // handleDeleteMultiplePlans,
      handleChangeField,
      submitNewData,
    },
    filterHandlers: {
      handleFilterChange,
      handleFilterApply,
      toggleFilterPanel,
      setChooseCountries,
    },
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
