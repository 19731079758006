import DateFnsUtils from '@date-io/date-fns';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { ServiceListFilters } from '@root/components/ServicesList/service-list-filters';
import { IServiceFilters } from '@root/pages/Services/types';
import { push } from 'connected-react-router';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SERVICE, { IServiceItem } from '../../services/api/requests/services';
import { IMeta } from '../../services/api/types';
import { editServiceData } from '../../store/entities/actions';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import { CustomTableHead, HeadCell, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import TableSkeleton from '../TableSkeleton';

export interface IServiceDsdfata {
  name: string;
  enabled: boolean;
  exigoId: number;
  recurringSku: string;
  oneDayRecurringSku: string;
  imageUrl: string;
  oneDayDeductSku: string;
  sku: string;
  bullets: string[];
  countryCodes: string[];
  createdAt?: string;
  updatedAt?: string;
}

const headCells: HeadCell[] = [
  {
    id: 'imageUrl',
    numeric: false,
    disablePadding: false,
    label: 'Image',
    disableSort: true,
    align: 'center',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
    align: 'left',
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Enabled',
    disableSort: true,
    align: 'center',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
    disableSort: true,
    align: 'center',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Updated At',
    disableSort: true,
    align: 'center',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: 'EDIT',
    align: 'center',
    disableSort: true,
  },
  {
    id: 'delete',
    numeric: false,
    disablePadding: false,
    label: 'DELETE',
    align: 'center',
    disableSort: true,
  },
];

const ServiceList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setSearch: (val: string) => void;
  setPage: (val: number) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
  search: string;
  toggleFilters: IServiceFilters;
  handleFilters: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = (props) => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const dateFns = new DateFnsUtils();
  const [recurringTypes, setSelectedRecurringTypes] = React.useState<string[]>([]);
  const [list, setList] = useState<IServiceItem[] | null>(null);
  const {
    loading,
    setLoading,
    setMeta,
    page,
    setPage,
    rowsPerPage,
    search,
    setSearch,
    toggleFilters,
    handleFilters,
  } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchServiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search]);

  const handleServiceDelete = useCallback(async (id: number) => {
    try {
      const response = await SERVICE.deleteService(id);
      if (response) {
        setNotification('success', {
          message: 'Success',
        });
        fetchServiceList();
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  }, [page, rowsPerPage, search]);

  const handleServiceEdit = (service: IServiceItem) => {
    dispatch(editServiceData(service));
    dispatch(push(pageLinks.createEditService));
  };

  const fetchServiceList = async () => {
    const data: Partial<IServiceFilters> & {
      page: number;
      limit: number;
      search: string;
      recurringTypes: string[];
    } = {
      page,
      limit: rowsPerPage,
      search,
      recurringTypes,
    };

    Object.keys(toggleFilters).forEach((key) => {
      const filterKey = key as keyof IServiceFilters;
      if (toggleFilters[filterKey]) {
        data[filterKey] = toggleFilters[filterKey];
      }
    });

    try {
      const response = await SERVICE.getList(data);

      if (response) {
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  return (
    <>
      <div className={classes.paper}></div>
      <ServiceListFilters
        recurringTypes={recurringTypes}
        setSelectedRecurringTypes={setSelectedRecurringTypes}
        setPage={setPage}
        setSearch={setSearch}
        toggleFilters={toggleFilters}
        handleFilters={handleFilters}
        submitFilters={fetchServiceList}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}

            {!loading &&
              list?.map((service: IServiceItem) => {
                const { id, name, imageUrl, createdAt, updatedAt, enabled } = service;

                const create = createdAt
                  ? dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm')
                  : '-';
                const update = updatedAt
                  ? dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm')
                  : '-';

                return (
                  <TableRow key={id}>
                    <TableCell align="center">
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="service"
                          style={{ maxWidth: 100, maxHeight: 100, objectFit: 'contain' }}
                        />
                      ) : (
                        'No Image'
                      )}
                    </TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="center">{enabled ? 'TRUE' : 'FALSE'}</TableCell>
                    <TableCell align="center">{create}</TableCell>
                    <TableCell align="center">{update}</TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.updateServices}>
                        <IconButton
                          aria-label="edit"
                          title="Edit"
                          onClick={() => handleServiceEdit(service)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteService}>
                        <DeleteModalButton
                          name={name}
                          entity={'Gateway'}
                          onDelete={() => handleServiceDelete(id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}

            {!loading && !list?.length && (
              <TableRow>
                <TableCell>There are no services</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ServiceList;
