import pickBy from 'lodash/pickBy';
import ApiUrls from '../apiUrls';
import apiClient from '../client';

export interface ICoupon {
  id?: number;
  couponCode: string;
  autogenerated?: boolean;
  title: string;
  description: string;
  usageRulesDescription?: string;
  userId: number | null;
  perUserLimit: number;
  stock: number;
  countries: string[];
  startAt: string | Date;
  expiredAt: string | null | Date;
  autopay: boolean;
  excludeAddonDiscount: boolean;
  published: boolean;
  allowCrossUsage: boolean;
  isForBundleSwitch: boolean;
  elite: boolean;
  planIds: string[];
  discount: number;
  currency: 'USD' | '%';
  forAction: 'RENEW' | 'INITIAL' | 'AFFILIATE_UPGRADE' | 'ANY';
  secret: string;
  cv: number | undefined;
  qv: number | undefined;
  scv: number | undefined;
  sqv: number | undefined;
  fsb: number | undefined;
  matrixBV: number | undefined;
  splitBaseValue: number | undefined;
}

export interface ICreateCouponResponse {
  data: {};
}

const request = (data: ICoupon) => {
  const finalData = pickBy(data, (val) => val !== null && val !== 'all' && val !== '');
  return apiClient
    .post<{ data: ICoupon }>(ApiUrls.createCoupon, finalData)
    .then((response) => response);
};

export default request;
