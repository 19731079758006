export const headCells = [
  { id: 'id', numeric: false, disablePadding: false, label: 'ID', disableSort: true },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Code',
    disableSort: true,
  },
  {
    id: 'Period',
    numeric: false,
    disablePadding: false,
    label: 'Days',
    disableSort: true,
  },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  {
    id: 'verificationStatus',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    disableSort: true,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    disableSort: true,
  },
];

export const emptyItem = {
  code: '',
  lengthInDays: 0,
  name: '',
  status: 'active',
};
