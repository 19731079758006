import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';
import { ICountry } from './getCountries';

export enum PAYMENT_METHOD_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IPaymentMethod {
  id: number;
  name: string;
  defaultProcessingFee: number;
  priority: number;
  createdAt: Date;
  updatedAt: Date;
  imageUrls: string[];
  countries: ICountry[];
  title: string;
  customFlow: boolean;
  status: PAYMENT_METHOD_STATUSES;
}

export interface IPaymentMethodResponse {
  data: {
    items: IPaymentMethod[];
    meta: IMeta;
  };
}

const request = ({ page, limit }: IPaginationParams) => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient
    .get<IPaymentMethodResponse>(ApiUrls.paymentMethodList + query)
    .then((response) => response);
};

export default request;
