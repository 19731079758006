import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getPaymentMethodList from '../../services/api/requests/getPaymentMethodList';
import { editVatItem } from '../../store/entities/actions';
import { selectVatForEditing } from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { useVatFormik } from './use-formik';

export const useVatForm = () => {
  const dispatch = useDispatch();
  const vatForEditing = useSelector(selectVatForEditing);
  const fk = useVatFormik(vatForEditing);
  const [options, setOptions] = useState<Array<IOption>>([]);
  const isEdit = !!vatForEditing;

  const getMethods = useCallback(async () => {
    try {
      const response = await getPaymentMethodList({ page: 1, limit: 50 });
      const options = response.data.data.items.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(options);
    } catch (e) {
      console.log(e);
    }
  }, [setOptions]);

  useEffect(() => {
    getMethods().catch(console.log);
  }, [getMethods]);

  useEffect(() => {
    return () => {
      dispatch(editVatItem(null));
    };
  }, [dispatch]);

  const state = {
    isEdit,
    fk,
    options,
  };

  return [state] as [typeof state];
};
