import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SERVICE, { IServiceItem } from '../../services/api/requests/services';
import { editServiceData } from '../../store/entities/actions';
import { selectServiceForEditing } from '../../store/entities/selectors';
import { useServiceFormik } from './use-formik';

export const useServiceForm = () => {
  const dispatch = useDispatch();
  const serviceForEditing = useSelector(selectServiceForEditing);
  const [editService, setEditService] = useState<IServiceItem | null>(null);
  const [selectedRecurringTypes, setSelectedRecurringTypes] = useState<string[]>([]);
  const fk = useServiceFormik(editService);
  const isEdit = !!editService;
  const recurringFieldsVisible = !!fk.values?.exigoId ;

  useEffect(() => {
    const fetchEditService = async () => {
      if (serviceForEditing?.id) {
        try {
          const response = await SERVICE.getServiceItem(serviceForEditing.id);
          
          setEditService(response.data.data);
        } catch (error) {
          console.log('error', error);
        }
      }
    };
    
    fetchEditService();
    return () => {
      dispatch(editServiceData(null));
    };
  }, [serviceForEditing, dispatch]);

  useEffect(() => {
    const recurringTypes = [
      ...new Set([
        ...Object.keys(editService?.skus || {}),
        ...Object.keys(editService?.recurringSkus || {}),
      ]),
    ];
    setSelectedRecurringTypes(recurringTypes);
  }, [editService, setSelectedRecurringTypes]);

  useEffect(() => {
    const skus = Object.keys(fk.values.skus || {}).filter(
      (item) => !selectedRecurringTypes.includes(item)
    );
    const recurringSkus = Object.keys(fk.values.recurringSkus || {}).find(
      (item) => !selectedRecurringTypes.includes(item)
    );

    if (skus.length) {
      fk.setFieldValue('skus', {
        ...Object.keys(fk.values.skus || {})
          .filter((item) => selectedRecurringTypes.includes(item))
          .reduce(
            (acc, key) => {
              acc[key] = fk.values?.skus?.[key] as string;
              return acc;
            },
            {} as Record<string, string>
          ),
      });
    }

    if (recurringSkus?.length) {
      fk.setFieldValue('recurringSkus', {
        ...Object.keys(fk.values.recurringSkus || {})
          .filter((item) => selectedRecurringTypes.includes(item))
          .reduce(
            (acc, key) => {
              acc[key] = fk.values?.recurringSkus?.[key] as string;
              return acc;
            },
            {} as Record<string, string>
          ),
      });
    }
  }, [selectedRecurringTypes, fk.values.skus, fk.values.recurringSkus]);

  const handleTogglesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    fk.setFieldValue('isOneTime', checked);
    if (checked) {
      fk.setFieldValue('recurringSkus', {});
      fk.setFieldValue('skus', {});
    } else {
      fk.setFieldValue('skus', {});
    }
  };

  const state = {
    recurringFieldsVisible,
    isEdit,
    fk,
    selectedRecurringTypes,
  };
  
  const handlers = { 
    setSelectedRecurringTypes,
    handleTogglesChange };

  return [state, handlers] as [typeof state, typeof handlers];
};
