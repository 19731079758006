import { PaymentStatus } from '@root/pages/Orders/types';
import getGatewayList, { IGateway } from '@root/services/api/requests/getGatewayList';
import { getOrder, IOrder } from '@root/services/api/requests/orders';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';

export const useDeclineOrders = () => {
  const [failedOrderId, setFailedOrderId] = useState<string>('');
  const [failedOrderData, setFailedOrderData] = useState<IOrder | null>(null);
  const [gateways, setGateways] = useState<IGateway[]>([]);

  async function fetchFailedOrder() {
    try {
      const res = await getOrder(failedOrderId);

      if (res) {
        setFailedOrderData(res.data.data);
      }
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  }

  const clearFailedOrder = () => {
    setFailedOrderData(null);
  };

  const failedTransaction = useMemo(
    () =>
      failedOrderData?.transactions.find(
        (el) =>
          Object.values(PaymentStatus).some((status) => status === el.status) &&
          failedOrderData.status !== 'Delivered'
      ),
    [failedOrderData]
  );

  const fetchGatewayList = async () => {
    try {
      const response = await getGatewayList({ page: 1, limit: 100 });
      const activeGateways = response.data.data.items.filter((item) => item.status === 'active');
      setGateways(activeGateways);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (failedOrderId.length > 0) fetchFailedOrder();
    else setFailedOrderData(null);
  }, [failedOrderId]);

  useEffect(() => {
    fetchGatewayList();
  }, []);

  const state = {
    failedOrderId,
    failedOrderData,
    failedTransaction,
    gateways,
  };

  const handlers = {
    setFailedOrderId,
    clearFailedOrder,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
