import { PayloadAction } from '@reduxjs/toolkit';
import { getRecurringTypes, IRecurringType } from '@root/services/api/requests/recurringTypes';
import SERVICE from '@root/services/api/requests/services';
import { AxiosResponse } from 'axios';
import { all, call, put, select, spawn, takeLatest, takeLeading } from 'redux-saga/effects';
import { Permissions } from '../components/Can';
import faqItemRequests from '../services/api/requests/faq';
import getAdminRoles, { IAdminRolesResponse } from '../services/api/requests/getAdminRoles';
import {
  getCountries,
  getCountryGroup,
  ICountry,
  ICountryGroupResponse,
} from '../services/api/requests/getCountries';
import getCountriesWithoutInstances, {
  ICountryWithoutInstances,
} from '../services/api/requests/getCountriesWithoutInstances';
import getInstances, { IGetInstance } from '../services/api/requests/getInstances';
import getPaymentMethodList, {
  IPaymentMethodResponse,
} from '../services/api/requests/getPaymentMethodList';
import getProducts, { IProductsResponse } from '../services/api/requests/getProducts';
import getServices, { IServiceResponse } from '../services/api/requests/getServices';
import getSKUList, { IGetSKUListResponse } from '../services/api/requests/getSKUList';
import makeUserDetailsRequest from '../services/api/requests/getUserDetails';
import membershipLevelRequests from '../services/api/requests/membershipLevels';
import mindhubGroupRequests from '../services/api/requests/mindhubGroups';
import notificationsRequests from '../services/api/requests/notifications';
import { getPermissions, IPermissionsResponse } from '../services/api/requests/permissions';
import syncServices from '../services/api/requests/syncServices';
import umustlookItemRequests from '../services/api/requests/umustlookItems';
import * as EntityActions from '../store/entities/actions';
import {
  saveCountries,
  saveCountryGroups,
  saveInstances,
  savePaymentMethods,
  saveServices,
  saveUpsellingRulesServices,
  setAdminRoles,
  setMembershipLevels,
  setPermissionGroups,
  setPermissions,
  setProducts,
  setRecurringTypes,
  setUserDetails,
} from '../store/entities/actions';
import { setLoader } from '../store/system/actions';
import { selectUserPermissions } from '../store/system/selectors';
import onAxiosError from '../utils/errorHandler';
import { isUserLogged } from '../utils/helpers';
import { IPaginationParams } from './../services/api/types';

const requestsWithPermissions = {
  [Permissions.readProductList]: [fetchProductsWorker],
  [Permissions.readAdminRoleList]: [fetchPermissionsWorker, fetchAdminRolesWorker],
  [Permissions.readInstanceList]: [fetchInstanceListWorker, fetchCountryGroupsWorker],
  [Permissions.readPayments]: [fetchPaymentMethodsWorker],
};

function* fetchStartDataWorker() {
  if (isUserLogged()) {
    const permissions: ReturnType<typeof selectUserPermissions> =
      yield select(selectUserPermissions);
    const data = Object.entries(requestsWithPermissions).reduce((acc: any, [key, value]) => {
      if (permissions?.includes(key)) {
        acc = [...acc, ...value];
      }
      return acc;
    }, []);
    yield all([
      spawn(fetchServicesWorker),
      spawn(fetchCountriesWithoutInstancesWorker),
      spawn(fetchSKUListWorker),
      spawn(fetchCountriesWorker),
      spawn(fetchRecurringTypesWorker),
      spawn(fetchUpsellingRulesServicesWorker),
      ...data.map((item: any) => spawn(item)),
    ]);
  } else {
  }
}

function* updateProductsWithPlansListWorker() {
  const permissionsRequest = {
    [Permissions.readProductList]: [fetchProductsWorker],
  };

  if (isUserLogged()) {
    const permissions: ReturnType<typeof selectUserPermissions> =
      yield select(selectUserPermissions);
    const data = Object.entries(permissionsRequest).reduce((acc: any, [key, value]) => {
      if (permissions?.includes(key)) {
        acc = [...acc, ...value];
      }
      return acc;
    }, []);

    yield all([...data.map((item: any) => spawn(item))]);
  }
}

function* fetchProductsWorker() {
  try {
    const productResponse: AxiosResponse<IProductsResponse> = yield getProducts();
    yield put(setProducts(productResponse?.data?.data));
  } catch (e) {}
}

function* fetchPermissionsWorker() {
  try {
    const permissionsResponse: AxiosResponse<IPermissionsResponse> = yield call(getPermissions);
    yield put(setPermissions(permissionsResponse.data.permissions));
    yield put(setPermissionGroups(permissionsResponse.data.groups));
  } catch (e) {}
}

function* fetchCountriesWorker() {
  try {
    const countries: AxiosResponse<ICountry[]> = yield call(getCountries);
    yield put(saveCountries(countries.data));
  } catch (e) {}
}

function* fetchRecurringTypesWorker() {
  try {
    const recurringTypes: AxiosResponse<IRecurringType[]> = yield call(getRecurringTypes);
    yield put(setRecurringTypes(recurringTypes.data));
  } catch (e) {}
}

function* fetchCountryGroupsWorker() {
  try {
    const response: AxiosResponse<ICountryGroupResponse> = yield call(getCountryGroup);
    yield put(saveCountryGroups(response.data.data.groups));
  } catch (e) {}
}

function* fetchCountriesWithoutInstancesWorker() {
  try {
    const countriesResponse: AxiosResponse<ICountryWithoutInstances[]> = yield call(
      getCountriesWithoutInstances
    );
    yield put(EntityActions.setCountriesWithoutInstances(countriesResponse.data));
  } catch (e) {}
}

function* fetchServicesWorker() {
  try {
    const services: AxiosResponse<IServiceResponse> = yield call(getServices);
    yield put(saveServices(services.data.data));
  } catch (e) {}
}

function* fetchUpsellingRulesServicesWorker() {
  try {
    const services: AxiosResponse<IServiceResponse> = yield call(SERVICE.getUpsellingRulesServices);
    yield put(saveUpsellingRulesServices(services.data.data));
  } catch (e) {}
}

function* fetchSKUListWorker() {
  try {
    const response: AxiosResponse<IGetSKUListResponse> = yield call(getSKUList, { searchTerm: '' });
    yield put(EntityActions.saveSKUList(response.data.data));
  } catch (e) {}
}

function* synchronizeWorker() {
  try {
    yield call(syncServices);
  } catch (e) {}
}

function* fetchPaymentMethodsWorker() {
  try {
    const paymentMethods: AxiosResponse<IPaymentMethodResponse> = yield call(getPaymentMethodList, {
      page: 1,
      limit: 100,
    });

    yield put(savePaymentMethods(paymentMethods.data.data.items));
  } catch (e) {}
}

function* fetchInstanceListWorker() {
  try {
    const response: AxiosResponse<IGetInstance[]> = yield call(getInstances);
    yield put(saveInstances(response.data));
  } catch (e) {}
}

function* fetchAdminRolesWorker() {
  try {
    const response: AxiosResponse<IAdminRolesResponse> = yield call(getAdminRoles);
    yield put(setAdminRoles(response.data.data));
  } catch (e) {}
}

function* getUserDetails({ payload }: PayloadAction<number>) {
  yield put(setLoader(true));
  try {
    let user = null;
    if (payload) {
      const response: AxiosResponse = yield call(makeUserDetailsRequest, payload);
      user = response.data.data;
    }
    yield put(setUserDetails(user));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* getMembershipLevels() {
  yield put(setLoader(true));
  try {
    const response: AxiosResponse = yield call(membershipLevelRequests.readMembershipLevelList);
    const levels = response.data.data;
    yield put(setMembershipLevels(levels));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* getUmustlookItems() {
  yield put(setLoader(true));
  try {
    const response: AxiosResponse = yield call(umustlookItemRequests.readUmustlookItemList);
    const levels = response.data.data;
    yield put(EntityActions.setUmustlookItems(levels));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* getMindhubGroupsItems({ payload }: PayloadAction<IPaginationParams>) {
  yield put(setLoader(true));
  try {
    const response: AxiosResponse = yield call(
      mindhubGroupRequests.readMindhubGroupListPagination,
      payload
    );
    const levels = response.data.data;
    yield put(EntityActions.setMindhubGroups(levels));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* getNotificationItems() {
  yield put(setLoader(true));
  try {
    const response: AxiosResponse = yield call(notificationsRequests.readNotificationList);
    const items = response.data.data;
    yield put(EntityActions.setNotifications(items));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* getFaqItems() {
  yield put(setLoader(true));
  try {
    const response: AxiosResponse = yield call(faqItemRequests.readFaqItemList);
    const items = response.data.data;
    yield put(EntityActions.setFaqItems(items));
    yield put(setLoader(false));
  } catch (e) {
    onAxiosError(e);
    yield put(setLoader(false));
  }
}

function* entitiesSaga() {
  yield spawn(fetchStartDataWorker);
  yield all([
    takeLatest(EntityActions.syncServices.type, synchronizeWorker),
    takeLatest(EntityActions.fetchCountries.type, fetchCountriesWorker),
    takeLatest(
      [EntityActions.fetchCountriesWithoutInstances.type, EntityActions.updateInstanceList.type],
      fetchCountriesWithoutInstancesWorker
    ),
    takeLatest(EntityActions.fetchStartData.type, fetchStartDataWorker),
    takeLatest(EntityActions.updateProductsWithPlansList.type, updateProductsWithPlansListWorker),
    takeLatest(EntityActions.fetchAdminRoles.type, fetchAdminRolesWorker),
    takeLatest(EntityActions.fetchPermissions.type, fetchPermissionsWorker),
    takeLatest(EntityActions.updateInstanceList.type, fetchInstanceListWorker),
    takeLeading(EntityActions.fetchUserDetails.type, getUserDetails),
    takeLeading(EntityActions.fetchMembershipLevels.type, getMembershipLevels),
    takeLatest(EntityActions.fetchUmustlookItems.type, getUmustlookItems),
    takeLatest(EntityActions.fetchMindhubGroups.type, getMindhubGroupsItems),
    takeLatest(EntityActions.fetchNotifications.type, getNotificationItems),
    takeLatest(EntityActions.fetchFaqItems.type, getFaqItems),
  ]);
}

export default entitiesSaga;
