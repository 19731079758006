import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export interface IMethod {
  id: number;
  name: string;
  defaultProcessingFee: number;
  priority: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGateway {
  id: number;
  rebillyId: string;
  name: string;
  currencies: string[];
  status: string;
  processingFee: number;
  exigoTransactionField: string;
  createdAt: Date;
  updatedAt: Date;
  gatewayMethod: IMethod;
  invoiceAbbr: string;
  companyName: string;
  invoiceAddress: string;
  healthCheckUrl: string;
  trnCode: string;
}

export interface IGatewayResponse {
  data: {
    items: IGateway[];
    meta: IMeta;
  };
}

const request = ({ page, limit }: IPaginationParams) => {
  const query = `?page=${page}&limit=${limit}`;
  return apiClient.get<IGatewayResponse>(ApiUrls.gatewayList + query).then((response) => response);
};

export default request;
