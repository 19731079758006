import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IPaginationParams } from '../types';

export interface IUpsellingRule {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  priority: number;
  plan: {
    id: number;
    name: string;
    level: string;
  };
  addon: {
    id: number;
    name: string;
    exigoId: number;
  };
}

export interface IReadUpsellingRule {
  planId: number;
}

export interface ICreateUpsellingRule {
  planId?: number;
  addonId?: number;
  changeNested: boolean;
  priority: number;
}

export interface IUpsellingRuleResponse {
  data: {
    items: IUpsellingRule[];
  };
}

const readUpsellingRules = ({ planId, page, limit }: IReadUpsellingRule & IPaginationParams) => {
  const query = `?planId=${planId}&page=${page}&limit=${limit}`;
  return apiClient
    .get<IUpsellingRuleResponse>(ApiUrls.readUpsellingRules + query)
    .then((response) => response);
};

const createUpsellingRule = (data: ICreateUpsellingRule) =>
  apiClient.post<IUpsellingRule>(ApiUrls.createUpsellingRules, data).then((response) => response);

const editUpsellingRule = (data: ICreateUpsellingRule, id: number) =>
  apiClient.put<IUpsellingRule>(ApiUrls.editUpsellingRules + id, data).then((response) => response);

const deleteUpsellingRule = (id: number) =>
  apiClient.delete<IUpsellingRule>(ApiUrls.deleteUpsellingRules + id).then((response) => response);

const upsellingRuleRequests = {
  readUpsellingRules,
  createUpsellingRule,
  editUpsellingRule,
  deleteUpsellingRule,
};

export default upsellingRuleRequests;
