import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import switchSubscriptionRuleRequests, {
  ISwitchSubscriptionRule,
} from '../../services/api/requests/switchSubscriptionRule';
import {
  editSwitchSubscriptionRulesData,
  updateProductsWithPlansList,
} from '../../store/entities/actions';
import { selectPlansAsObjects } from '../../store/entities/selectors';
import { selectUserPermissions } from '../../store/system/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const useStyles = makeStyles({
  disabled: {
    backgroundColor: '#f2002f26',
  },
});

const headCells = [
  {
    id: 'planIdFrom',
    numeric: false,
    disablePadding: false,
    label: 'Switch From',
    disableSort: true,
  },
  { id: 'planIdTo', numeric: false, disablePadding: false, label: 'Switch To', disableSort: true },
];

export default function SwitchSubscriptionRules() {
  const classes = useStandardTableStyles();
  const localClasses = useStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(selectUserPermissions);
  const planData = useSelector(selectPlansAsObjects);
  const [list, setList] = React.useState<ISwitchSubscriptionRule[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [planFromFilterId, setPlanFromFilterId] = React.useState<number | null>(null);
  const [planToFilterId, setPlanToFilterId] = React.useState<number | null>(null);

  const fetchSwitchSubscriptionRuleList = async () => {
    if (permissions?.includes(Permissions.readProductList)) {
      try {
        const response = await switchSubscriptionRuleRequests.readSwitchSubscriptionRuleList();
        setList(response.data.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.warn(e.message);
      }
    }
  };

  useEffect(() => {
    fetchSwitchSubscriptionRuleList();
    dispatch(updateProductsWithPlansList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSwitchSubscriptionRule = () => {
    dispatch(push(pageLinks.createEditSwitchSubscriptionRule));
  };

  const deleteItem = async (id: number | string) => {
    try {
      await switchSubscriptionRuleRequests.deleteSwitchSubscriptionRule(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchSwitchSubscriptionRuleList();
    } catch (e) {
      console.warn(e.message);
    }
  };

  const editItem = (item: ISwitchSubscriptionRule) => {
    dispatch(editSwitchSubscriptionRulesData(item));
    dispatch(push(`${pageLinks.createEditSwitchSubscriptionRule}`));
  };

  const options = useMemo(() => {
    return Object.values(planData).map((item) => {
      return { value: item.id, label: item.name };
    });
  }, [planData]);

  const listWithNames = useMemo(() => {
    return list?.map((item) => {
      const { planIdFrom, planIdTo } = item;

      const fromName = planData[planIdFrom]?.name;
      const toName = planData[planIdTo]?.name;

      return { ...item, fromName, toName };
    });
  }, [list, planData]);

  const filteredList = useMemo(() => {
    return listWithNames?.filter((item) => {
      if (planFromFilterId && planToFilterId) {
        return item.planIdFrom === planFromFilterId && item.planIdTo === planToFilterId;
      }
      if (planFromFilterId) {
        return item.planIdFrom === planFromFilterId;
      }
      if (planToFilterId) {
        return item.planIdTo === planToFilterId;
      }
      return true;
    });
  }, [listWithNames, planFromFilterId, planToFilterId]);

  return (
    <Grid className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Bundle Switch Subscription Rule</Title>
            <Can perform={Permissions.createSwitchingRules}>
              <Button
                onClick={createSwitchSubscriptionRule}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddCircleIcon />}
              >
                Add Switch Subscription Rule
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="permissionIds"
                options={options}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Choose a plan from" variant="outlined" fullWidth />
                )}
                onChange={(e, selected) => setPlanFromFilterId(selected?.value || null)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="permissionIds"
                options={options}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Choose a plan to" variant="outlined" fullWidth />
                )}
                onChange={(e, selected) => setPlanToFilterId(selected?.value || null)}
              />
            </Grid>
          </Grid>
        </Box>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead classes={classes} headCells={headCells} />
            <TableBody>
              {loading && <TableSkeleton />}
              {filteredList?.map((rule) => {
                const { sku, id, enabled, fromName, toName } = rule;
                return (
                  <TableRow key={id} className={clsx(enabled ? {} : localClasses.disabled)}>
                    <TableCell>{fromName || '-'}</TableCell>
                    <TableCell>{toName || '-'}</TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.updateSwitchingRules}>
                        <IconButton aria-label="edit" title={'Edit'} onClick={() => editItem(rule)}>
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteSwitchingRules}>
                        <DeleteModalButton
                          name={`Subscription Rule with SKU ${sku}`}
                          entity={'Switch Subscription Rule'}
                          onDelete={() => deleteItem(id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })}
              {!loading && !!filteredList && !filteredList?.length && (
                <TableRow>
                  <TableCell>There are no subscription rules to switch</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}
