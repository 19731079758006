import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICountry } from '../../services/api/requests/getInstances';
import { editMindhubGroupData, setMindhubGroups } from '../../store/entities/actions';
import { selectCountries } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import mindhubGroupRequests, { IMindhubGroup } from './../../services/api/requests/mindhubGroups';
import { IMeta } from './../../services/api/types';

export const useMindhubGroupsTable = () => {
  const dispatch = useDispatch();
  const countries: ICountry[] = useSelector(selectCountries);

  const [loading, setLoading] = useState<boolean>(true);
  const [meta, setMeta] = useState<IMeta>({ limit: 0, page: 1, total: 0, totalPages: 0 });
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [mindhubGroupList, setList] = useState<IMindhubGroup[]>([]);

  const fetchMindhubGroupList = async () => {
    try {
      const response = await mindhubGroupRequests.readMindhubGroupListPagination({
        page,
        limit: rowsPerPage,
      });
      setLoading(false);
      const dataItems = response.data.items.map((item) => ({
        ...item,
        countryIds: item.countryIds || [],
      }));
      setList(dataItems);
      dispatch(setMindhubGroups(dataItems));
      setMeta(response.data.meta);
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleDelete = async (id: number | string) => {
    try {
      await mindhubGroupRequests.deleteMindhubGroup(id);
      setNotification('success', {
        message: 'Success',
      });
      await fetchMindhubGroupList();
    } catch (e) {
      console.warn((e as AxiosError).message);
    }
  };

  const handleEdit = (item: IMindhubGroup) => {
    dispatch(editMindhubGroupData(item));
    dispatch(push(pageLinks.createEditMindhubGroup));
  };

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    fetchMindhubGroupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const state = {
    mindhubGroupList,
    countries,
    meta,
    page,
    rowsPerPage,
    loading,
  };

  const handlers = { handleEdit, handleDelete, handleChangePage, handleChangeRowsPerPage };

  return [state, handlers] as [typeof state, typeof handlers];
};
