import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { push } from 'connected-react-router';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Can, { Permissions } from '../../components/Can';
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';
import DeleteModalButton from '../../components/DeleteModalButton';
import TableSkeleton from '../../components/TableSkeleton';
import Title from '../../components/Title';
import { ICountry } from '../../services/api/requests/getInstances';
import pageLinks from '../../utils/pageLinks';
import { useMindhubGroupsTable } from './use-table';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSort: true,
  },
  {
    id: 'mindhubGroupId',
    numeric: false,
    disablePadding: false,
    label: 'Mindhub GroupID',
    disableSort: true,
  },
  {
    id: 'countries',
    numeric: false,
    disablePadding: false,
    label: 'Countries',
    disableSort: true,
  },

  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

const EnhancedTable: FC = () => {
  const classes = useStandardTableStyles();
  const dispatch = useDispatch();
  const [
    { mindhubGroupList, countries, meta, loading },
    { handleChangePage, handleChangeRowsPerPage, handleEdit, handleDelete },
  ] = useMindhubGroupsTable();

  return (
    <div className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Mindhub groups</Title>
            <Can perform={Permissions.createMindhubGroup}>
              <Button
                onClick={() => dispatch(push(pageLinks.createEditMindhubGroup))}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<GroupAddIcon />}
              >
                Add group
              </Button>
            </Can>
          </div>
        </Paper>
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <CustomTableHead headCells={headCells} classes={classes} />
          <TableBody>
            {loading && <TableSkeleton />}
            {!loading && !mindhubGroupList?.length ? (
              <TableRow>
                <TableCell>There are no Mindhub group</TableCell>
              </TableRow>
            ) : (
              mindhubGroupList.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell align={'left'}>{item.name}</TableCell>
                    <TableCell align={'left'}>{item.mindhubBranchId}</TableCell>
                    <TableCell align={'left'}>
                      <Box display="flex" flexWrap="wrap">
                        {countries
                          .filter((country) => item.countryIds.includes(country.id))
                          .map((country: ICountry, idx: number, arr: ICountry[]) => (
                            <Tooltip title={country.name} key={country.code}>
                              <Box m={0.5} display="flex" flexWrap="wrap">{`${country.code}${
                                arr.length - 1 === idx ? '' : ', '
                              }`}</Box>
                            </Tooltip>
                          ))}
                      </Box>
                    </TableCell>

                    <TableCell align="center">
                      <Can perform={Permissions.updateMindhubGroup}>
                        <IconButton
                          aria-label="edit"
                          title={'Edit'}
                          onClick={() => handleEdit(item)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    </TableCell>
                    <TableCell align="center">
                      <Can perform={Permissions.deleteMindhubGroup}>
                        <DeleteModalButton
                          name={item.name}
                          entity={'countries group'}
                          onDelete={() => handleDelete(item.id)}
                        />
                      </Can>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={meta.total}
          rowsPerPage={meta.limit}
          page={meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default EnhancedTable;
