import DateFnsUtils from '@date-io/date-fns';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getPaymentMethod from '../../services/api/requests/getPaymentMethod';
import getPaymentMethodList, {
  IPaymentMethod,
} from '../../services/api/requests/getPaymentMethodList';
import { IMeta } from '../../services/api/types';
import { editPaymentMethodData } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import TableSkeleton from '../TableSkeleton';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name', disableSort: true },
  { id: 'title', numeric: false, disablePadding: false, label: 'Title', disableSort: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', disableSort: true },
  {
    id: 'defaultProcessingFee',
    numeric: false,
    disablePadding: false,
    label: 'Default Processing Fee',
    disableSort: true,
  },
  {
    id: 'customFlow',
    numeric: false,
    disablePadding: false,
    label: 'Custom Flow',
    disableSort: true,
  },
  {
    id: 'priority',
    numeric: false,
    disablePadding: false,
    label: 'UI priority order',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Date Updated',
    disableSort: true,
  },
];

const VatRulesList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
}> = (props) => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const dispatch = useDispatch();

  const { loading, setLoading, setMeta, page, rowsPerPage } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readPayments)) {
      fetchPaymentMethodsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const [list, setList] = useState<IPaymentMethod[] | null>(null);

  const fetchPaymentMethodsList = () => {
    getPaymentMethodList({ page, limit: rowsPerPage }).then((response) => {
      if (response) {
        setLoading(false);
        setList(response.data.data.items);
        setMeta(response.data.data.meta);
      }
    });
  };

  const handlePaymentMethodEdit = async (paymentMethod: IPaymentMethod) => {
    try {
      const response = await getPaymentMethod(paymentMethod.id);
      dispatch(editPaymentMethodData(response.data));
      dispatch(push(pageLinks.createEditPaymentMethod));
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            list?.map((paymentMethod: IPaymentMethod) => {
              const {
                id,
                name,
                title,
                status,
                defaultProcessingFee,
                priority,
                customFlow,
                createdAt,
                updatedAt,
              } = paymentMethod;
              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
              const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

              return (
                <TableRow key={id}>
                  <TableCell align="left">{name}</TableCell>
                  <TableCell align="left">{title}</TableCell>
                  <TableCell align="left">{status}</TableCell>
                  <TableCell align="left">{defaultProcessingFee}</TableCell>
                  <TableCell align="left">{customFlow ? 'yes' : 'no'}</TableCell>
                  <TableCell align="left">{priority}</TableCell>
                  <TableCell>{create}</TableCell>
                  <TableCell>{update}</TableCell>
                  <TableCell align="left">
                    <Can perform={Permissions.updatePaymentMethod}>
                      <IconButton
                        aria-label="edit"
                        title="Edit"
                        onClick={() => handlePaymentMethodEdit(paymentMethod)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !list?.length && (
            <TableRow>
              <TableCell>There are no payment methods</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VatRulesList;
